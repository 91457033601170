import { cva } from 'class-variance-authority';

import { colorMap } from '@constants/colors';

import Actions from '@components/Actions';
import TickList from '@components/TickList';
import TextRenderer from '@components/TextRenderer';

import gradientShapes from './gradientShapes';

import flowStyles from '@styles/utilities/flow.module.css';
import measureStyles from '@styles/utilities/measures.module.css';
import themeStyles from '@styles/themes.module.css';
import textStyles from '@styles/textStyles.module.css';
import * as styles from './PageSection.module.css';

export const DEFAULT_TITLE_TAG = 'h2';

const sectionVariants = cva(styles.root, {
  variants: {
    theme: {
      white: [themeStyles.white, styles.hasTheme],
      light: [themeStyles.light, styles.hasTheme],
      dark: [themeStyles.dark, styles.hasTheme],
    },
    hasLinearGradient: {
      true: styles.hasLinearGradient,
    },
    gradientShape: {
      ArrowLeft: styles.gradientOffsetLeft,
      ArrowRight: styles.gradientOffsetRight,
    },
    layout: {
      vertical: styles.verticalLayout,
      horizontal: styles.horizontalLayout,
    },
    sectionWidth: {
      small: styles.sectionWidthSmall,
    },
    contentWidth: {
      wide: styles.contentWidthWide,
    },
    hasMeta: {
      true: styles.hasMeta,
    },
    offsetForEyebrow: {
      true: styles.offsetForEyebrow,
    },
    sticky: {
      true: styles.sticky,
    },
    withBorder: {
      true: styles.withBorder,
    },
    actionsOnRight: {
      true: styles.actionsOnRight,
    },
    paddingTop: {
      '2xl': styles.pt2XL,
      '3xl': styles.pt3XL,
      '4xl': styles.pt4XL,
      '5xl': styles.pt5XL,
      '6xl': styles.pt6XL,
    },
    paddingBottom: {
      '2xl': styles.pb2XL,
      '3xl': styles.pb3XL,
      '4xl': styles.pb4XL,
      '5xl': styles.pb5XL,
      '6xl': styles.pb6XL,
    },
  },
});

const headlineVariants = cva(styles.title, {
  variants: {
    titleTextSize: {
      small: [textStyles.headlineS, measureStyles.wide],
      medium: [textStyles.headlineM, measureStyles.medium],
      large: [textStyles.headlineL, measureStyles.narrow],
      xlarge: [textStyles.headlineXL, measureStyles.narrow],
    },
  },
});

const descriptionVariants = cva(styles.description, {
  variants: {
    descriptionTextSize: {
      small: textStyles.bodyS,
      medium: textStyles.bodyM,
      large: textStyles.bodyL,
      xlarge: textStyles.bodyXL,
    },
  },
});

const childrenVariants = cva(styles.content, {
  variants: {
    blockSpacing: {
      small: [flowStyles.root, flowStyles.small],
      medium: [flowStyles.root, flowStyles.medium],
      large: [flowStyles.root, flowStyles.large],
      xlarge: [flowStyles.root, flowStyles.xlarge],
    },
  },
});

const PageSection = ({
  children,
  className,
  blockSpacing = 'medium',
  theme = null,
  gradient = null,
  gradientShape,
  markerColor = null,
  layout = 'vertical',
  sectionWidth = 'default',
  contentWidth = 'default',
  eyebrow,
  title,
  titleTextSize = 'medium',
  description,
  descriptionTextSize = 'medium',
  listItems,
  withBorder = false,
  sticky = true,
  headingTag = DEFAULT_TITLE_TAG,
  actions,
  actionsOnRight = false,
  anchorId,
  trackingScope = '',
  paddingTop,
  paddingBottom,
}) => {
  const sectionClasses = sectionVariants(
    {
      theme: theme || undefined,
      hasLinearGradient: gradient !== null && theme !== null && !gradientShape,
      gradientShape: gradientShape,
      layout,
      sectionWidth,
      contentWidth,
      hasMeta: !!(eyebrow || title || description || listItems),
      offsetForEyebrow: eyebrow && (title || description),
      sticky: sticky && layout === 'horizontal',
      withBorder,
      actionsOnRight,
      paddingTop,
      paddingBottom,
    },
    className,
  );

  const headlineCssClasses = headlineVariants({ titleTextSize });
  const descriptionCssClasses = descriptionVariants({ descriptionTextSize });
  const childrenCssClasses = childrenVariants({ blockSpacing });

  const Heading = headingTag;
  const TitleTag = !eyebrow ? headingTag : 'p';

  const sectionStyles = {
    '--page-section-gradient-color': gradient && colorMap[gradient],
    '--section-marker-color': markerColor && colorMap[markerColor],
  };

  const GradientShape = gradientShape ? gradientShapes[gradientShape] : null;

  return (
    <section className={sectionClasses} style={sectionStyles} id={anchorId}>
      {GradientShape && (
        <div className={styles.gradientShape}>
          <GradientShape />
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.text}>
          {eyebrow && (
            <Heading className={textStyles.eyebrow}>{eyebrow}</Heading>
          )}
          {title && (
            <TextRenderer
              text={title}
              as={TitleTag}
              className={headlineCssClasses}
            />
          )}
          {description && (
            <TextRenderer
              className={descriptionCssClasses}
              text={description}
            />
          )}
          {listItems && <TickList className={styles.list} items={listItems} />}
        </div>
        {actions && (
          <div className={styles.actions}>
            <Actions
              actions={actions}
              trackingScope={trackingScope}
              theme={theme ?? undefined}
            />
          </div>
        )}
      </div>
      <div className={childrenCssClasses}>{children}</div>
    </section>
  );
};

// We don't want a display name for this component, as it really is a HOC
// eslint-disable-next-line
export const withPageSection = (Component, overwrites) => (props) => {
  return (
    <PageSection {...props} {...overwrites}>
      <Component {...props} />
    </PageSection>
  );
};

export default PageSection;
